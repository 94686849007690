import Vue from "vue";
import _ from "lodash";

import {
  SET_FIELD,
  SET_FIELD_VALUE,
  SET_BLOCK_FIELDS,
  CLEAR_STORE,
  TOTAL_FIELD_ERROR_ADD,
  TOTAL_FIELD_ERROR_REMOVE,
  SET_FIELD_ERROR
} from "./mutation-types";

export default {

  [SET_FIELD](state, { land_type_id, land_category_id, isCalculated, calculateParams, dependedFields }) {
    Vue.set(state, "fields", {
      ...state.fields,
      [land_type_id]: {
        ...(state.fields?.[land_type_id] || {}),
        [land_category_id]: {
          ...(state.fields?.[land_type_id]?.[land_category_id] || {}),
          calculateParams,
          land_type_id,
          land_category_id,
          isCalculated,
          dependedFields: [
            ...(state.fields?.[land_type_id]?.[land_category_id]?.dependedFields || []),
            ...(dependedFields || []),
          ],
          area_amount: 0,
          hasError: false
        },
      },
    });
  },
  [SET_FIELD_VALUE](state, { land_type_id, land_category_id, value}) {
    state.fields[land_type_id][land_category_id].area_amount = parseFloat(value) || 0;
  },
  [SET_FIELD_ERROR](state, { land_type_id, land_category_id, value}) {
    state.fields[land_type_id][land_category_id].hasError = value;
  },
  [SET_BLOCK_FIELDS](state, { isEditable }) {
    state.isEditable = isEditable;
  },
  [CLEAR_STORE](state) {
    Object.keys(state.fields).forEach((land_type_id) => {
      Object.keys(state.fields[land_type_id]).forEach((land_category_id) => {
        state.fields[land_type_id][land_category_id].area_amount = 0;
      })
    })
    state.isEditable = true;
  },
  [TOTAL_FIELD_ERROR_ADD](state, { land_category_id, land_type_id }){
    if (!state.totalFieldsByCategotyWithErrors.some(obj => obj.land_category_id == land_category_id || obj.land_type_id == land_type_id)) {
      state.totalFieldsByCategotyWithErrors.push({ land_category_id: land_category_id, land_type_id: land_type_id})
    }
  },
  [TOTAL_FIELD_ERROR_REMOVE](state, { land_category_id, land_type_id }){
    state.totalFieldsByCategotyWithErrors = state.totalFieldsByCategotyWithErrors.filter(obj => obj.land_category_id !== land_category_id || obj.land_type_id !== land_type_id);
  }
}