//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data() {
    return {
      mainMenu: false,
      authMenu: false,
    };
  },
  created() {
    const onClickOutside = (e) => {
      this.authMenu = this.$el.contains(e.target) && this.authMenu;
    };
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );
  },
  computed: {
    isBaseRoute() {
      return this.$route.name == "base";
    },
    availableLocales() {
      return this.$i18n.locales;
    },
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
    menuItems() {
      return this.$store.state.headerBase;
    },
    authMenuItems() {
      return this.$store.getters.allowedBaseUserMenuItems;
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      this.$router.push("/base/auth/login");
    },
    redirectToLogin() {
      this.$router.push({
        path: "/base/auth/login",
        query: { redirect: this.$route.fullPath },
      });
    },
    redirectToRegister() {
      this.$router.push({
        path: "/base/auth/register",
        query: { redirect: this.$route.fullPath },
      });
    },
    changeLocale(newLocale) {
      this.$i18n.setLocale(newLocale);
      this.$nuxt.$emit("changeLocale", newLocale);
    },
    cabinetLink(){
      if(!this.$auth.loggedIn){
        this.redirectToLogin();
      }
      else {
        if(!this.authMenu){
          this.authMenu = !this.authMenu
        }
        else{
          if(this.$auth?.user.role == 3){
            this.$router.push({path: "/arm/application/income"});
          }
          else{
            this.$router.push({path: "/base/personalCabinet"});
          }
          this.authMenu = !this.authMenu
        }
      }
    }
  },
};
