export const StatusNewDesign = () => import('../../modules/aisgzk_module_ui/components/StatusNewDesign.vue' /* webpackChunkName: "components/status-new-design" */).then(c => wrapFunctional(c.default || c))
export const BaseFAQ = () => import('../../modules/aisgzk_module_ui/components/Base/FAQ.vue' /* webpackChunkName: "components/base-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const BaseFooter = () => import('../../modules/aisgzk_module_ui/components/Base/Footer.vue' /* webpackChunkName: "components/base-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseHeader = () => import('../../modules/aisgzk_module_ui/components/Base/Header.vue' /* webpackChunkName: "components/base-header" */).then(c => wrapFunctional(c.default || c))
export const BaseLogin = () => import('../../modules/aisgzk_module_ui/components/Base/Login.vue' /* webpackChunkName: "components/base-login" */).then(c => wrapFunctional(c.default || c))
export const BaseMain = () => import('../../modules/aisgzk_module_ui/components/Base/Main.vue' /* webpackChunkName: "components/base-main" */).then(c => wrapFunctional(c.default || c))
export const BasePopularServices = () => import('../../modules/aisgzk_module_ui/components/Base/PopularServices.vue' /* webpackChunkName: "components/base-popular-services" */).then(c => wrapFunctional(c.default || c))
export const BaseStatistics = () => import('../../modules/aisgzk_module_ui/components/Base/Statistics.vue' /* webpackChunkName: "components/base-statistics" */).then(c => wrapFunctional(c.default || c))
export const ArmHeader = () => import('../../modules/aisgzk_module_ui/components/Arm/Header.vue' /* webpackChunkName: "components/arm-header" */).then(c => wrapFunctional(c.default || c))
export const ArmProfile = () => import('../../modules/aisgzk_module_ui/components/Arm/Profile.vue' /* webpackChunkName: "components/arm-profile" */).then(c => wrapFunctional(c.default || c))
export const ArmProfileSidebar = () => import('../../modules/aisgzk_module_ui/components/Arm/ProfileSidebar.vue' /* webpackChunkName: "components/arm-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CadastralSearchAttributes = () => import('../../modules/aisgzk_module_ui/components/CadastralSearch/Attributes.vue' /* webpackChunkName: "components/cadastral-search-attributes" */).then(c => wrapFunctional(c.default || c))
export const CadastralSearchByIIN = () => import('../../modules/aisgzk_module_ui/components/CadastralSearch/ByIIN.vue' /* webpackChunkName: "components/cadastral-search-by-i-i-n" */).then(c => wrapFunctional(c.default || c))
export const CadastralSearchByNumber = () => import('../../modules/aisgzk_module_ui/components/CadastralSearch/ByNumber.vue' /* webpackChunkName: "components/cadastral-search-by-number" */).then(c => wrapFunctional(c.default || c))
export const DocWrapper = () => import('../../modules/aisgzk_module_ui/components/Doc/Wrapper.vue' /* webpackChunkName: "components/doc-wrapper" */).then(c => wrapFunctional(c.default || c))
export const GeoportalBasemapSwitcher = () => import('../../modules/aisgzk_module_ui/components/Geoportal/basemapSwitcher.vue' /* webpackChunkName: "components/geoportal-basemap-switcher" */).then(c => wrapFunctional(c.default || c))
export const GeoportalMain = () => import('../../modules/aisgzk_module_ui/components/Geoportal/main.vue' /* webpackChunkName: "components/geoportal-main" */).then(c => wrapFunctional(c.default || c))
export const GeoportalMap = () => import('../../modules/aisgzk_module_ui/components/Geoportal/map.vue' /* webpackChunkName: "components/geoportal-map" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPassport = () => import('../../modules/aisgzk_module_ui/components/Geoportal/passport.vue' /* webpackChunkName: "components/geoportal-passport" */).then(c => wrapFunctional(c.default || c))
export const ModalsArchiveModal = () => import('../../modules/aisgzk_module_ui/components/Modals/ArchiveModal.vue' /* webpackChunkName: "components/modals-archive-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDownloadReport = () => import('../../modules/aisgzk_module_ui/components/Modals/DownloadReport.vue' /* webpackChunkName: "components/modals-download-report" */).then(c => wrapFunctional(c.default || c))
export const ModalsFilterByServiceModal = () => import('../../modules/aisgzk_module_ui/components/Modals/FilterByServiceModal.vue' /* webpackChunkName: "components/modals-filter-by-service-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRemoveReport = () => import('../../modules/aisgzk_module_ui/components/Modals/RemoveReport.vue' /* webpackChunkName: "components/modals-remove-report" */).then(c => wrapFunctional(c.default || c))
export const ModalsSaveReport = () => import('../../modules/aisgzk_module_ui/components/Modals/SaveReport.vue' /* webpackChunkName: "components/modals-save-report" */).then(c => wrapFunctional(c.default || c))
export const ModalsZemBalansConfirm = () => import('../../modules/aisgzk_module_ui/components/Modals/ZemBalansConfirm.vue' /* webpackChunkName: "components/modals-zem-balans-confirm" */).then(c => wrapFunctional(c.default || c))
export const ProcessDirectionBlock = () => import('../../modules/aisgzk_module_ui/components/Process/DirectionBlock.vue' /* webpackChunkName: "components/process-direction-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessDocBlock = () => import('../../modules/aisgzk_module_ui/components/Process/DocBlock.vue' /* webpackChunkName: "components/process-doc-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessEmpsSelectBlock = () => import('../../modules/aisgzk_module_ui/components/Process/EmpsSelectBlock.vue' /* webpackChunkName: "components/process-emps-select-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldBlock = () => import('../../modules/aisgzk_module_ui/components/Process/FieldBlock.vue' /* webpackChunkName: "components/process-field-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessMain = () => import('../../modules/aisgzk_module_ui/components/Process/Main.vue' /* webpackChunkName: "components/process-main" */).then(c => wrapFunctional(c.default || c))
export const ProcessMapBlock = () => import('../../modules/aisgzk_module_ui/components/Process/MapBlock.vue' /* webpackChunkName: "components/process-map-block" */).then(c => wrapFunctional(c.default || c))
export const ProcessNavbar = () => import('../../modules/aisgzk_module_ui/components/Process/Navbar.vue' /* webpackChunkName: "components/process-navbar" */).then(c => wrapFunctional(c.default || c))
export const ProcessViewBlock = () => import('../../modules/aisgzk_module_ui/components/Process/ViewBlock.vue' /* webpackChunkName: "components/process-view-block" */).then(c => wrapFunctional(c.default || c))
export const ZemBalans = () => import('../../modules/aisgzk_module_ui/components/ZemBalans/index.vue' /* webpackChunkName: "components/zem-balans" */).then(c => wrapFunctional(c.default || c))
export const ZemBalansReportAgriculturalTable = () => import('../../modules/aisgzk_module_ui/components/ZemBalansReport/AgriculturalTable.vue' /* webpackChunkName: "components/zem-balans-report-agricultural-table" */).then(c => wrapFunctional(c.default || c))
export const ZemBalansReportInput = () => import('../../modules/aisgzk_module_ui/components/ZemBalansReport/Input.vue' /* webpackChunkName: "components/zem-balans-report-input" */).then(c => wrapFunctional(c.default || c))
export const ZemBalansReportNonagriculturalTable = () => import('../../modules/aisgzk_module_ui/components/ZemBalansReport/NonagriculturalTable.vue' /* webpackChunkName: "components/zem-balans-report-nonagricultural-table" */).then(c => wrapFunctional(c.default || c))
export const ZemBalansReportTotalSquare = () => import('../../modules/aisgzk_module_ui/components/ZemBalansReport/totalSquare.vue' /* webpackChunkName: "components/zem-balans-report-total-square" */).then(c => wrapFunctional(c.default || c))
export const RegistryList = () => import('../../modules/aisgzk_module_ui/components/registry/list.vue' /* webpackChunkName: "components/registry-list" */).then(c => wrapFunctional(c.default || c))
export const RegistryMain = () => import('../../modules/aisgzk_module_ui/components/registry/main.vue' /* webpackChunkName: "components/registry-main" */).then(c => wrapFunctional(c.default || c))
export const RegistryTable = () => import('../../modules/aisgzk_module_ui/components/registry/table.vue' /* webpackChunkName: "components/registry-table" */).then(c => wrapFunctional(c.default || c))
export const AdminLayersCreateModal = () => import('../../modules/aisgzk_module_ui/components/Admin/Layers/CreateModal.vue' /* webpackChunkName: "components/admin-layers-create-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminLayersList = () => import('../../modules/aisgzk_module_ui/components/Admin/Layers/List.vue' /* webpackChunkName: "components/admin-layers-list" */).then(c => wrapFunctional(c.default || c))
export const AdminLayersRegistryList = () => import('../../modules/aisgzk_module_ui/components/Admin/Layers/RegistryList.vue' /* webpackChunkName: "components/admin-layers-registry-list" */).then(c => wrapFunctional(c.default || c))
export const BaseAppMonitor = () => import('../../modules/aisgzk_module_ui/components/Base/App/Monitor.vue' /* webpackChunkName: "components/base-app-monitor" */).then(c => wrapFunctional(c.default || c))
export const BaseFAQItem = () => import('../../modules/aisgzk_module_ui/components/Base/FAQ/Item.vue' /* webpackChunkName: "components/base-f-a-q-item" */).then(c => wrapFunctional(c.default || c))
export const BaseMainMap = () => import('../../modules/aisgzk_module_ui/components/Base/Main/Map.vue' /* webpackChunkName: "components/base-main-map" */).then(c => wrapFunctional(c.default || c))
export const BaseMainScreen = () => import('../../modules/aisgzk_module_ui/components/Base/Main/Screen.vue' /* webpackChunkName: "components/base-main-screen" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceInfo = () => import('../../modules/aisgzk_module_ui/components/Base/Service/Info.vue' /* webpackChunkName: "components/base-service-info" */).then(c => wrapFunctional(c.default || c))
export const BaseServiceList = () => import('../../modules/aisgzk_module_ui/components/Base/Service/List.vue' /* webpackChunkName: "components/base-service-list" */).then(c => wrapFunctional(c.default || c))
export const ArmAppList = () => import('../../modules/aisgzk_module_ui/components/Arm/App/List.vue' /* webpackChunkName: "components/arm-app-list" */).then(c => wrapFunctional(c.default || c))
export const ArmAppListIncome = () => import('../../modules/aisgzk_module_ui/components/Arm/App/ListIncome.vue' /* webpackChunkName: "components/arm-app-list-income" */).then(c => wrapFunctional(c.default || c))
export const ArmAppMonitor = () => import('../../modules/aisgzk_module_ui/components/Arm/App/Monitor.vue' /* webpackChunkName: "components/arm-app-monitor" */).then(c => wrapFunctional(c.default || c))
export const ArmAppMonitorAppStep = () => import('../../modules/aisgzk_module_ui/components/Arm/App/MonitorAppStep.vue' /* webpackChunkName: "components/arm-app-monitor-app-step" */).then(c => wrapFunctional(c.default || c))
export const ArmServiceList = () => import('../../modules/aisgzk_module_ui/components/Arm/Service/List.vue' /* webpackChunkName: "components/arm-service-list" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPanel = () => import('../../modules/aisgzk_module_ui/components/Geoportal/panel/index.vue' /* webpackChunkName: "components/geoportal-panel" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldCheckbox = () => import('../../modules/aisgzk_module_ui/components/Process/Field/Checkbox.vue' /* webpackChunkName: "components/process-field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldGeo = () => import('../../modules/aisgzk_module_ui/components/Process/Field/Geo.vue' /* webpackChunkName: "components/process-field-geo" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldTable = () => import('../../modules/aisgzk_module_ui/components/Process/Field/Table.vue' /* webpackChunkName: "components/process-field-table" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPanelTabDetails = () => import('../../modules/aisgzk_module_ui/components/Geoportal/panel/tab/details.vue' /* webpackChunkName: "components/geoportal-panel-tab-details" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPanelTabDetailsAttrs = () => import('../../modules/aisgzk_module_ui/components/Geoportal/panel/tab/detailsAttrs.vue' /* webpackChunkName: "components/geoportal-panel-tab-details-attrs" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPanelTabFoundObject = () => import('../../modules/aisgzk_module_ui/components/Geoportal/panel/tab/foundObject.vue' /* webpackChunkName: "components/geoportal-panel-tab-found-object" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPanelTabLandObject = () => import('../../modules/aisgzk_module_ui/components/Geoportal/panel/tab/landObject.vue' /* webpackChunkName: "components/geoportal-panel-tab-land-object" */).then(c => wrapFunctional(c.default || c))
export const GeoportalPanelTabLayers = () => import('../../modules/aisgzk_module_ui/components/Geoportal/panel/tab/layers.vue' /* webpackChunkName: "components/geoportal-panel-tab-layers" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../uicore/components/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const NumberFormat = () => import('../../uicore/components/NumberFormat.vue' /* webpackChunkName: "components/number-format" */).then(c => wrapFunctional(c.default || c))
export const Status = () => import('../../uicore/components/Status.vue' /* webpackChunkName: "components/status" */).then(c => wrapFunctional(c.default || c))
export const AdminAdmins = () => import('../../uicore/components/Admin/Admins.vue' /* webpackChunkName: "components/admin-admins" */).then(c => wrapFunctional(c.default || c))
export const AdminApps = () => import('../../uicore/components/Admin/Apps.vue' /* webpackChunkName: "components/admin-apps" */).then(c => wrapFunctional(c.default || c))
export const AdminCalendar = () => import('../../uicore/components/Admin/Calendar.vue' /* webpackChunkName: "components/admin-calendar" */).then(c => wrapFunctional(c.default || c))
export const AdminEmployeeWS = () => import('../../uicore/components/Admin/EmployeeWS.vue' /* webpackChunkName: "components/admin-employee-w-s" */).then(c => wrapFunctional(c.default || c))
export const AdminEmployees = () => import('../../uicore/components/Admin/Employees.vue' /* webpackChunkName: "components/admin-employees" */).then(c => wrapFunctional(c.default || c))
export const AdminServices = () => import('../../uicore/components/Admin/Services.vue' /* webpackChunkName: "components/admin-services" */).then(c => wrapFunctional(c.default || c))
export const AdminWS = () => import('../../uicore/components/Admin/WS.vue' /* webpackChunkName: "components/admin-w-s" */).then(c => wrapFunctional(c.default || c))
export const ArmFooter = () => import('../../uicore/components/Arm/Footer.vue' /* webpackChunkName: "components/arm-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseProfile = () => import('../../uicore/components/Base/Profile.vue' /* webpackChunkName: "components/base-profile" */).then(c => wrapFunctional(c.default || c))
export const DocDownloadAllBtn = () => import('../../uicore/components/Doc/DownloadAllBtn.vue' /* webpackChunkName: "components/doc-download-all-btn" */).then(c => wrapFunctional(c.default || c))
export const FAQPage = () => import('../../uicore/components/FAQ/Page.vue' /* webpackChunkName: "components/f-a-q-page" */).then(c => wrapFunctional(c.default || c))
export const FieldControlCatalog = () => import('../../uicore/components/FieldControl/Catalog.vue' /* webpackChunkName: "components/field-control-catalog" */).then(c => wrapFunctional(c.default || c))
export const FieldControlCatalogSelect = () => import('../../uicore/components/FieldControl/CatalogSelect.vue' /* webpackChunkName: "components/field-control-catalog-select" */).then(c => wrapFunctional(c.default || c))
export const FieldControlDate = () => import('../../uicore/components/FieldControl/Date.vue' /* webpackChunkName: "components/field-control-date" */).then(c => wrapFunctional(c.default || c))
export const FieldControlNumber = () => import('../../uicore/components/FieldControl/Number.vue' /* webpackChunkName: "components/field-control-number" */).then(c => wrapFunctional(c.default || c))
export const FieldControlText = () => import('../../uicore/components/FieldControl/Text.vue' /* webpackChunkName: "components/field-control-text" */).then(c => wrapFunctional(c.default || c))
export const ModalsAdminCreate = () => import('../../uicore/components/Modals/AdminCreate.vue' /* webpackChunkName: "components/modals-admin-create" */).then(c => wrapFunctional(c.default || c))
export const ModalsAppResults = () => import('../../uicore/components/Modals/AppResults.vue' /* webpackChunkName: "components/modals-app-results" */).then(c => wrapFunctional(c.default || c))
export const ModalsAppReturn = () => import('../../uicore/components/Modals/AppReturn.vue' /* webpackChunkName: "components/modals-app-return" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePassword = () => import('../../uicore/components/Modals/ChangePassword.vue' /* webpackChunkName: "components/modals-change-password" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePhone = () => import('../../uicore/components/Modals/ChangePhone.vue' /* webpackChunkName: "components/modals-change-phone" */).then(c => wrapFunctional(c.default || c))
export const ModalsChangePhoneEmail = () => import('../../uicore/components/Modals/ChangePhoneEmail.vue' /* webpackChunkName: "components/modals-change-phone-email" */).then(c => wrapFunctional(c.default || c))
export const ModalsConfirmModal = () => import('../../uicore/components/Modals/ConfirmModal.vue' /* webpackChunkName: "components/modals-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsConfirmReturn = () => import('../../uicore/components/Modals/ConfirmReturn.vue' /* webpackChunkName: "components/modals-confirm-return" */).then(c => wrapFunctional(c.default || c))
export const ModalsDocGenerate = () => import('../../uicore/components/Modals/DocGenerate.vue' /* webpackChunkName: "components/modals-doc-generate" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmployeeAdd = () => import('../../uicore/components/Modals/EmployeeAdd.vue' /* webpackChunkName: "components/modals-employee-add" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmployeeCreate = () => import('../../uicore/components/Modals/EmployeeCreate.vue' /* webpackChunkName: "components/modals-employee-create" */).then(c => wrapFunctional(c.default || c))
export const ModalsEmployeeEdit = () => import('../../uicore/components/Modals/EmployeeEdit.vue' /* webpackChunkName: "components/modals-employee-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalsProfileSave = () => import('../../uicore/components/Modals/ProfileSave.vue' /* webpackChunkName: "components/modals-profile-save" */).then(c => wrapFunctional(c.default || c))
export const ModalsSelectAppPeriod = () => import('../../uicore/components/Modals/SelectAppPeriod.vue' /* webpackChunkName: "components/modals-select-app-period" */).then(c => wrapFunctional(c.default || c))
export const ModalsTSList = () => import('../../uicore/components/Modals/TSList.vue' /* webpackChunkName: "components/modals-t-s-list" */).then(c => wrapFunctional(c.default || c))
export const ModalsTableModal = () => import('../../uicore/components/Modals/TableModal.vue' /* webpackChunkName: "components/modals-table-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsTableViewModal = () => import('../../uicore/components/Modals/TableViewModal.vue' /* webpackChunkName: "components/modals-table-view-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsWithdrawAppModal = () => import('../../uicore/components/Modals/WithdrawAppModal.vue' /* webpackChunkName: "components/modals-withdraw-app-modal" */).then(c => wrapFunctional(c.default || c))
export const ProcessInputBlock = () => import('../../uicore/components/Process/InputBlock.vue' /* webpackChunkName: "components/process-input-block" */).then(c => wrapFunctional(c.default || c))
export const RegistriesList = () => import('../../uicore/components/Registries/List.vue' /* webpackChunkName: "components/registries-list" */).then(c => wrapFunctional(c.default || c))
export const AdminControlDeleteApps = () => import('../../uicore/components/Admin/Control/DeleteApps.vue' /* webpackChunkName: "components/admin-control-delete-apps" */).then(c => wrapFunctional(c.default || c))
export const AdminControlDeleteDrafts = () => import('../../uicore/components/Admin/Control/DeleteDrafts.vue' /* webpackChunkName: "components/admin-control-delete-drafts" */).then(c => wrapFunctional(c.default || c))
export const AdminControlMain = () => import('../../uicore/components/Admin/Control/Main.vue' /* webpackChunkName: "components/admin-control-main" */).then(c => wrapFunctional(c.default || c))
export const AdminLayersDeleteModal = () => import('../../uicore/components/Admin/Layers/DeleteModal.vue' /* webpackChunkName: "components/admin-layers-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminLayersEditModal = () => import('../../uicore/components/Admin/Layers/EditModal.vue' /* webpackChunkName: "components/admin-layers-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const ArmAppProcess = () => import('../../uicore/components/Arm/App/Process.vue' /* webpackChunkName: "components/arm-app-process" */).then(c => wrapFunctional(c.default || c))
export const ArmServiceInfo = () => import('../../uicore/components/Arm/Service/Info.vue' /* webpackChunkName: "components/arm-service-info" */).then(c => wrapFunctional(c.default || c))
export const BaseAppList = () => import('../../uicore/components/Base/App/List.vue' /* webpackChunkName: "components/base-app-list" */).then(c => wrapFunctional(c.default || c))
export const BaseAppProcess = () => import('../../uicore/components/Base/App/Process.vue' /* webpackChunkName: "components/base-app-process" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldCatalog = () => import('../../uicore/components/Process/Field/Catalog.vue' /* webpackChunkName: "components/process-field-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldDate = () => import('../../uicore/components/Process/Field/Date.vue' /* webpackChunkName: "components/process-field-date" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldGeoCatalog = () => import('../../uicore/components/Process/Field/GeoCatalog.vue' /* webpackChunkName: "components/process-field-geo-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldLine = () => import('../../uicore/components/Process/Field/Line.vue' /* webpackChunkName: "components/process-field-line" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldNumber = () => import('../../uicore/components/Process/Field/Number.vue' /* webpackChunkName: "components/process-field-number" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldPoint = () => import('../../uicore/components/Process/Field/Point.vue' /* webpackChunkName: "components/process-field-point" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldPolygon = () => import('../../uicore/components/Process/Field/Polygon.vue' /* webpackChunkName: "components/process-field-polygon" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldRegistryCatalog = () => import('../../uicore/components/Process/Field/RegistryCatalog.vue' /* webpackChunkName: "components/process-field-registry-catalog" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldScreen = () => import('../../uicore/components/Process/Field/Screen.vue' /* webpackChunkName: "components/process-field-screen" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldStatic = () => import('../../uicore/components/Process/Field/Static.vue' /* webpackChunkName: "components/process-field-static" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldText = () => import('../../uicore/components/Process/Field/Text.vue' /* webpackChunkName: "components/process-field-text" */).then(c => wrapFunctional(c.default || c))
export const ProcessFieldMixin = () => import('../../uicore/components/Process/Field/mixin.js' /* webpackChunkName: "components/process-field-mixin" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
