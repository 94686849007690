//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SET_ACTIVE_PAGE, SET_STATUS } from "~/store/userApps/mutation-types";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      isAppStatusesListShow: { myApps: true, income: true },
      isSidebarShow: false,
      incomeAppsUrl: '/arm/application/income',
      myAppsUrl: '/arm/application/list',
      adminUrl: '/arm/administration',
      reportsUrl: '/arm/reports',
      analyticsUrl: '/arm/analytics',
      servicesUrl: '/arm/service/list',
      cadNumberSearch: '/arm/cad_number_search'
    };
  },
  methods: {
    ...mapMutations("userApps", [SET_STATUS, SET_ACTIVE_PAGE]),
    toggleSidebar() {
      this.isSidebarShow = !this.isSidebarShow;
    },
    getAppsByStatus(path) {
      this[SET_STATUS]("all");
      this[SET_ACTIVE_PAGE](1);
      this.goTo(path);
    },
    goTo(path) {
      this.$router.push(path);
    },
    async logout() {
      await this.$auth.logout();
      this.$router.push("/arm/login");
    },
  },
};
