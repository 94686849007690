import geoportalSearchStore from "@aisgzk_module_ui/store/geoportalSearch";
import landBalansStore from "@aisgzk_module_ui/store/landBalans";
export default function ({ store }) {
    store.registerModule('landBalans', landBalansStore)
    store.registerModule('geoportalSearch', geoportalSearchStore)
    // base header
    store.commit("setBaseHeaderMenuItem", {
        to: "/base/zem_balans",
        name: "land_balance",
    });

    store.commit("setArmHeaderMenuItem", {
        to: "/arm/zem_balans",
        name: "land_balance",
    });

    store.commit("setBaseHeaderMenuItem", {
        to: "/base/offices",
        name: "menu.offices",
    });

    // store.commit("setBaseHeaderMenuItem", {
    //     to: "#footer",
    //     name: "header.contacts",
    // });
}
