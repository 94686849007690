import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b5f5eb7e = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/zem_balans.vue' /* webpackChunkName: "" */))
const _16faf186 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/service/list/index.vue' /* webpackChunkName: "" */))
const _01ab5b10 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/service/list/_parentId.vue' /* webpackChunkName: "" */))
const _4849aebb = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "" */))
const _6b48c89a = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/service/gishagi/index.vue' /* webpackChunkName: "" */))
const _b8f9d9ba = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/service/gishagi/docs.vue' /* webpackChunkName: "" */))
const _8df6ea86 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/personalCabinet.vue' /* webpackChunkName: "" */))
const _56239d7c = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/offices.vue' /* webpackChunkName: "" */))
const _503a420e = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/land/index.vue' /* webpackChunkName: "" */))
const _734368be = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/land/_id.vue' /* webpackChunkName: "" */))
const _919f6252 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/index.vue' /* webpackChunkName: "" */))
const _d633fc40 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/base/auth/forgotPass.vue' /* webpackChunkName: "" */))
const _acc7ff92 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/zem_balans_report.vue' /* webpackChunkName: "" */))
const _6883e34c = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/zem_balans.vue' /* webpackChunkName: "" */))
const _44fce0de = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/service/list/index.vue' /* webpackChunkName: "" */))
const _746619fa = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "" */))
const _9f27ac86 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/reports/report-template.vue' /* webpackChunkName: "" */))
const _51067d38 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/reports/report-generate.vue' /* webpackChunkName: "" */))
const _273d4c9c = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/reports/index.vue' /* webpackChunkName: "" */))
const _18d25e03 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/login.vue' /* webpackChunkName: "" */))
const _4f2abaec = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/index.vue' /* webpackChunkName: "" */))
const _e5622138 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/employeeCabinet.vue' /* webpackChunkName: "" */))
const _49a9f03f = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/cad_number_search.vue' /* webpackChunkName: "" */))
const _5194adb4 = () => interopDefault(import('../modules/aisgzk_module_ui/pages/arm/administration.vue' /* webpackChunkName: "" */))
const _76fb3756 = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _90d794d2 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _e72d5aee = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _8727dd82 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _7d69e17c = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _5027a652 = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _6f0338f4 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _066a943d = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _3185689e = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _0a110572 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _70890686 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _534dd28a = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _13d27328 = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _330fb9c9 = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _2fea2e8f = () => interopDefault(import('../uicore/pages/arm/admin/system/registryLayers.vue' /* webpackChunkName: "pages/arm/admin/system/registryLayers" */))
const _40f375ce = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _0d5abb1c = () => interopDefault(import('../uicore/pages/arm/admin/system/layers.vue' /* webpackChunkName: "pages/arm/admin/system/layers" */))
const _04b208dd = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _40323724 = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _75480fa1 = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _a38cec4c = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _c1c4fe4e = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _97059474 = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _58602e0e = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _56b50ec3 = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _09af6d03 = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _5e212304 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _e55922c6 = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _728be468 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _04c00693 = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _6e5cd426 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _72a149dc = () => interopDefault(import('../uicore/pages/base/registries.vue' /* webpackChunkName: "pages/base/registries" */))
const _005ac04f = () => interopDefault(import('../uicore/pages/base/geoportal/index.vue' /* webpackChunkName: "pages/base/geoportal/index" */))
const _5bb097a5 = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _ffa55b8e = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _2776c050 = () => interopDefault(import('../uicore/pages/arm/geoportal/index.vue' /* webpackChunkName: "pages/arm/geoportal/index" */))
const _fa6d5618 = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _096416ff = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/base/zem_balans",
    component: _b5f5eb7e,
    name: "base-zem_balans"
  }, {
    path: "/base/service/list",
    component: _16faf186,
    name: "base-service-list"
  }, {
    path: "/base/service/list/:parentId",
    component: _01ab5b10,
    name: "base-service-list-parentId"
  }, {
    path: "/base/service/info/:tsrId",
    component: _4849aebb,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/service/gishagi",
    component: _6b48c89a,
    name: "base-service-gishagi"
  }, {
    path: "/base/service/gishagi/docs",
    component: _b8f9d9ba,
    name: "base-service-gishagi-docs"
  }, {
    path: "/base/personalCabinet",
    component: _8df6ea86,
    name: "base-personalCabinet"
  }, {
    path: "/base/offices",
    component: _56239d7c,
    name: "base-offices"
  }, {
    path: "/base/land",
    component: _503a420e,
    name: "base-land"
  }, {
    path: "/base/land/:id",
    component: _734368be,
    name: "base-land-id"
  }, {
    path: "/base",
    component: _919f6252,
    name: "base"
  }, {
    path: "/base/auth/forgotPass",
    component: _d633fc40,
    name: "base-auth-forgotPass"
  }, {
    path: "/arm/zem_balans_report",
    component: _acc7ff92,
    name: "arm-zem_balans_report"
  }, {
    path: "/arm/zem_balans",
    component: _6883e34c,
    name: "arm-zem_balans"
  }, {
    path: "/arm/service/list",
    component: _44fce0de,
    name: "arm-service-list"
  }, {
    path: "/arm/service/list/:parentId",
    component: _746619fa,
    name: "arm-service-list-parentId"
  }, {
    path: "/arm/reports/report-template",
    component: _9f27ac86,
    name: "arm-reports-report-template"
  }, {
    path: "/arm/reports/report-generate",
    component: _51067d38,
    name: "arm-reports-report-generate"
  }, {
    path: "/arm/reports",
    component: _273d4c9c,
    name: "arm-reports"
  }, {
    path: "/arm/login",
    component: _18d25e03,
    name: "arm-login"
  }, {
    path: "/arm",
    component: _4f2abaec,
    name: "arm"
  }, {
    path: "/arm/employeeCabinet",
    component: _e5622138,
    name: "arm-employeeCabinet"
  }, {
    path: "/arm/cad_number_search",
    component: _49a9f03f,
    name: "arm-cad_number_search"
  }, {
    path: "/arm/administration",
    component: _5194adb4,
    name: "arm-administration"
  }, {
    path: "/",
    component: _76fb3756,
    name: "index"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _90d794d2,
    name: "base-check_doc-fileId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _e72d5aee,
    name: "arm-resetPass-token"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _8727dd82,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/application/process/:asId?",
    component: _7d69e17c,
    name: "base-application-process-asId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _5027a652,
    name: "base-application-monitor-appId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _6f0338f4,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _066a943d,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _3185689e,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _0a110572,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _70890686,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/base/application/result/appId",
    component: _534dd28a,
    name: "base-application-result-appId"
  }, {
    path: "/arm/application/view/asId",
    component: _13d27328,
    name: "arm-application-view-asId"
  }, {
    path: "/arm/admin/system/route",
    component: _330fb9c9,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/admin/system/registryLayers",
    component: _2fea2e8f,
    name: "arm-admin-system-registryLayers"
  }, {
    path: "/arm/admin/system/list",
    component: _40f375ce,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/layers",
    component: _0d5abb1c,
    name: "arm-admin-system-layers"
  }, {
    path: "/arm/admin/system/control",
    component: _04b208dd,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/calendar",
    component: _40323724,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/app",
    component: _75480fa1,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/org/service",
    component: _a38cec4c,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/org/employee",
    component: _c1c4fe4e,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/app",
    component: _97059474,
    name: "arm-admin-org-app"
  }, {
    path: "/base/auth/register",
    component: _58602e0e,
    name: "base-auth-register"
  }, {
    path: "/base/auth/profile",
    component: _56b50ec3,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/login",
    component: _09af6d03,
    name: "base-auth-login"
  }, {
    path: "/base/application/list",
    component: _5e212304,
    name: "base-application-list"
  }, {
    path: "/arm/application/list",
    component: _e55922c6,
    name: "arm-application-list"
  }, {
    path: "/arm/application/income",
    component: _728be468,
    name: "arm-application-income"
  }, {
    path: "/oldAppsList/employee",
    component: _04c00693,
    name: "oldAppsList-employee"
  }, {
    path: "/oldAppsList/applicant",
    component: _6e5cd426,
    name: "oldAppsList-applicant"
  }, {
    path: "/base/registries",
    component: _72a149dc,
    name: "base-registries"
  }, {
    path: "/base/geoportal",
    component: _005ac04f,
    name: "base-geoportal"
  }, {
    path: "/base/faq",
    component: _5bb097a5,
    name: "base-faq"
  }, {
    path: "/arm/profile",
    component: _ffa55b8e,
    name: "arm-profile"
  }, {
    path: "/arm/geoportal",
    component: _2776c050,
    name: "arm-geoportal"
  }, {
    path: "/arm/forgotPass",
    component: _fa6d5618,
    name: "arm-forgotPass"
  }, {
    path: "/Test",
    component: _096416ff,
    name: "Test"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
