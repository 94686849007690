import _ from "lodash";
import {
  UPDATE_FIELD,
  INIT_FIELD
} from "./action-types";
import {
    SET_FIELD_VALUE,
    SET_FIELD
} from "./mutation-types";


export default {
    [INIT_FIELD]({ commit }, { land_type_id, land_category_id, isCalculated, calculateParams}) {
        commit(SET_FIELD, { land_type_id, land_category_id, isCalculated, calculateParams });

        if (!_.isEmpty(calculateParams)) {
            calculateParams.land_type_ids.forEach(type_id => {
                calculateParams.land_category_ids.forEach(category_id => {
                    commit(SET_FIELD, {
                        dependedFields: [{land_type_id, land_category_id}],
                        land_category_id: category_id,
                        land_type_id: type_id,
                        isCalculated: isCalculated
                    });
                });
            });
        }
    },
    [UPDATE_FIELD]({ dispatch, commit, state}, { land_type_id, land_category_id, value}) {
        commit(SET_FIELD_VALUE, { land_type_id, land_category_id, value });

        const field = state.fields[land_type_id][land_category_id];
        field.dependedFields.forEach(({land_type_id, land_category_id}) => {
            const depField = state.fields[land_type_id][land_category_id];
            let sum = 0;
            depField?.calculateParams?.land_type_ids.forEach(type_id => {
                depField?.calculateParams?.land_category_ids.forEach(category_id => {
                    sum += state.fields[type_id][category_id].area_amount;
                });
            });
            dispatch(UPDATE_FIELD, {land_type_id, land_category_id, value: sum});
        })
    }
}