import { render, staticRenderFns } from "./layouts.arm.2641fcee.vue?vue&type=template&id=30b7f2c0&scoped=true&"
import script from "./layouts.arm.2641fcee.vue?vue&type=script&lang=js&"
export * from "./layouts.arm.2641fcee.vue?vue&type=script&lang=js&"
import style0 from "./layouts.arm.2641fcee.vue?vue&type=style&index=0&id=30b7f2c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b7f2c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArmHeader: require('/var/www/aisgzk/ui/modules/aisgzk_module_ui/components/Arm/Header.vue').default,ArmProfileSidebar: require('/var/www/aisgzk/ui/modules/aisgzk_module_ui/components/Arm/ProfileSidebar.vue').default})
