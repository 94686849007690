export default {
    fields: (state) => state.fields,
    isEditable: (state) => state.isEditable,
    totalFieldsByCategotyWithErrors: (state) => state.totalFieldsByCategotyWithErrors,
    getField: (state) => (land_type_id, land_category_id) => {
        let fields = state.fields;
        
        let field = fields[land_type_id]?.[land_category_id];
        
        if (!field) return null;
        
        return field.area_amount;
    },
    fieldWithError: (state) => (land_type_id, land_category_id) => {
        let fields = state.fields;
        
        let field = fields[land_type_id]?.[land_category_id];
        
        if (!field) return false;
        
        return field.hasError;
    }
}