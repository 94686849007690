import _ from "lodash";

import {
  SET_FOUND_OBJECT,
  SET_PROCESS_INDICATOR
} from "./mutation-types";

export default {

  [SET_FOUND_OBJECT](state, { info }) {
      state.foundObject = info
  },
  [SET_PROCESS_INDICATOR](state, searchInProcess) {
    state.searchInProcess = searchInProcess
},
}