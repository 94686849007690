export default () => ({
  isEditable: true,
  fields: {},
  fieldsFotTotalValueValidation: {
    5: {total_field: 4, land_type_ids: [6]},
    6: {total_field: 4, land_type_ids: [5]},
    9: {total_field: 8, land_type_ids: [10]},
    10: {total_field: 8, land_type_ids: [9]},
    12: {total_field: 11, land_type_ids: [13, 14]},
    13: {total_field: 11, land_type_ids: [12, 14]},
    14: {total_field: 11, land_type_ids: [13, 14]},
    17: {total_field: 16, land_type_ids: [18, 19]},
    18: {total_field: 16, land_type_ids: [17, 19]},
    19: {total_field: 16, land_type_ids: [18, 19]},
    21: {total_field: 20, land_type_ids: []},
    24: {total_field: 23, land_type_ids: [25, 26, 27]},
    25: {total_field: 23, land_type_ids: [24, 26, 27]},
    26: {total_field: 23, land_type_ids: [24, 25, 27]},
    27: {total_field: 23, land_type_ids: [24, 25, 26]},
    34: {total_field: 33, land_type_ids: [35, 36, 37, 38]},
    35: {total_field: 33, land_type_ids: [34, 36, 37, 38]},
    36: {total_field: 33, land_type_ids: [34, 35, 37, 38]},
    37: {total_field: 33, land_type_ids: [34, 35, 36, 38]},
    38: {total_field: 33, land_type_ids: [34, 35, 36, 37]}
  },
  totalFieldsValidation: {
    4: {fields: [5, 6]},
    8: {fields: [9, 10]},
    11: {fields: [12, 13, 14, 15, 16, 17, 18, 19]},
    16: {fields: [17, 18, 19], isEqualToTheSum: true},
    20: {fields: [21], isEqualToTheSum: true},
    23: {fields: [24, 25, 26, 27], isEqualToTheSum: true},
    33: {fields: [34, 35, 36, 37, 38], isEqualToTheSum: true}
  },
  totalFieldsByCategotyWithErrors: []
});